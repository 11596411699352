import React from 'react'
import PaymentType from './views/StepPaymentType'
import PaymentCredential from './views/StepPaymentCredential'
import StepWaiter from './views/StepWaiter'
import StepColorScreen from './views/StepColorScreen'
import StepCamera from './views/StepCamera'
import StepAmount from './views/StepAmount'
import StepTips from './views/StepTips'
import StepFinished from './views/StepFinished'
import StepEmailSent from './views/StepEmailSent'
import StepReadStampayCard from './views/StepReadStampayCard'

import { ReactComponent as Step1 } from './images/step1.svg'
import { ReactComponent as Step2 } from './images/step2.svg'
import { ReactComponent as Step3 } from './images/step3.svg'
import { ReactComponent as Step4 } from './images/step4.svg'
import { ReactComponent as Step5 } from './images/step5.svg'
import { ReactComponent as Step6 } from './images/step6.svg'

const steps = (skipTips, isToGo, skipCamera) => {
    return {
        'step-1': {
            icon: <Step1/>,
            showOnTimeline: true,
            component: (props) => <StepAmount {...props}/>,
            showNavigation: true,
        },
        'step-2': {
            showOnTimeline: false,
            component: (props) => <PaymentType {...props}/>,
        },
        'step-read-voucher-card': {
            showOnTimeline: false,
            component: (props) => <StepReadStampayCard {...props}/>, //QR Code camera with PIN
        },
        'step-credentials': {
            showOnTimeline: false,
            component: (props) => <PaymentCredential {...props}/>,
            shogitwNavigation: true,
        },
        'step-start-payment': {
            icon: <Step6/>,
            showOnTimeline: true,
            component: (props) => <StepFinished {...props}/>,
            showNavigation: false,
        },
        'step-6': {
            icon: <Step6/>,
            showOnTimeline: true,
            component: (props) => <StepFinished {...props}/>,
            showNavigation: false,
        },
        'step-6/sent': {
            icon: <Step6/>,
            showOnTimeline: false,
            component: (props) => <StepEmailSent {...props}/>,
            showNavigation: false,
        },
    }
}

export default steps
