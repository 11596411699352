import { CLEAR_STATE, REMOVE_PSP_REQUEST, SET_IS_DONATION, SET_PAYMENT_GATEWAY_DATA, SET_PAYMENT_METHOD } from '../actions/types'

const initState = {
    paymentGatewayData: null,
    isDonation: false,
    paymentMethod: null,
}

export function paymentData (
    state = initState,
    action,
) {
    switch (action.type) {
        case SET_PAYMENT_GATEWAY_DATA:
            return { ...state, paymentGatewayData: action.payload }
        case SET_IS_DONATION:
            return { ...state, isDonation: action.payload }
        case SET_PAYMENT_METHOD:
            return { ...state, paymentMethod: action.paymentMethod }
        case REMOVE_PSP_REQUEST:
            return { ...state, paymentMethod: null }
        case CLEAR_STATE: {
            return { ...initState }
        }
        default:
            return state
    }
}

export function isPaymentGatewayModeSelector (state) {
    return !!(state.paymentGatewayData && state.paymentGatewayData.returnUrl && state.paymentGatewayData.custom && state.paymentGatewayData.notifyUrl)
}
