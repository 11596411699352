import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '../components/Container'
import styled, { keyframes } from 'styled-components'
import { clearStepsHistory } from '../actions/wizardStateActions'
import EmailForm from '../components/EmailForm'
import { createOrUpdateClient, setClientEmail, setClientLocale } from '../actions/clientActions'
import Footer from '../components/Footer'
import RemoteSubmitButton from '../components/RemoteSubmitButton'
import { useTranslation } from 'react-i18next'
import { formatDate, getValueCentsInCurrencyFormat } from '../utils'
import { fetchTransaction, persistTransaction } from '../actions/transactionActions'
import { Redirect } from 'react-router-dom'
import { usePurgeHistory, useTracker } from '../hooks'
import { errorHandler } from '../errorHandler'
import Text from '../components/Text'
import { Column, Columns } from '../components/Columns'
import Processing from './Processing'
import { fetchPspRequest } from '../actions/pspRequestActions'
import { fetchVoucherRequest } from '../actions/voucherRequestActions'
import { setHideRestaurantInHeader, setTransactionFinished } from '../actions/appStateActions'
import IconLabel from '../components/IconLabel'
import locationIcon from '../images/voucher/pin_icon.svg'
import calendarIcon from '../images/calendar_icon.svg'
import giftCardIcon from '../images/voucher/gift_card_icon.svg'
import { ReactComponent as checkoutCircle } from '../images/checkout_circle.svg'
import { ReactComponent as tick } from '../images/tick.svg'

const flipHeads = keyframes`
  from {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    transform: rotateY(0);
  }
  to {
    -webkit-transform: rotateY(1800deg);
    -moz-transform: rotateY(1800deg);
    transform: rotateY(1800deg);
  }
`

const CheckoutCircle = styled(checkoutCircle)`
  position: absolute;
  top: 0;
  left: 0;
  path {
      fill: ${props => props.theme.actionColor};
    }
`

const CheckoutTick = styled(tick)`
  width: 80%;
  padding-bottom: 20px;
  float:right;
  path {
      fill: ${props => props.theme.boldActionColor};
    }
`

const CheckoutAnimation = styled.div`
  position: relative;
  margin: 10px auto 30px;
  width: 92px;
  height: 92px;
  
  animation: ${flipHeads} 5s ease-in-out infinite;
`

const StyledCurrencyText = styled.div`
  text-align: center;
  margin-top: 10px;
`

const StyledAmountDescriptionText = styled.div`
  text-align: center;
  font-size: 0.8em;
  color: ${props => props.theme.secondaryInputColor}
`

const AmountText = styled(Text)`
  font-size: 4em;
  margin-top: -4px;
`

const ConfirmationContainer = styled(Container)`
  justify-content: center;
  align-items: center;
`

const StyledIconLabel = styled(IconLabel)`
  padding-left: 10px;
`

const InfoColumns = styled(Columns)`
  ${props => props.expanded ? 'width:80%;' : 'width:60%;'}
`

const StepFinished = ({ wizard }) => {
    const paymentData = useSelector(state => state.paymentData)
    const restaurant = useSelector(state => state.restaurant)
    const client = useSelector(state => state.client)
    const transaction = useSelector(state => state.transaction)
    const pspRequest = useSelector(state => state.pspRequest)
    const voucherRequest = useSelector(state => state.voucherRequest)
    const voucherBalance = useSelector(state => state.voucherBalance)
    const { paymentGatewayData } = paymentData
    const { loyaltyCardId } = transaction
    const returnUrl = paymentGatewayData?.returnUrl
    const [emailValid, setEmailValid] = useState(false)
    const [showRestartButton, setShowRestartButton] = useState(false)
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation()
    useTracker(null)
    usePurgeHistory()
    dispatch(setHideRestaurantInHeader(true))

    useEffect(() => {
        dispatch(clearStepsHistory)
    }, [dispatch])

    useEffect(() => {
        let timeout = null
        // TODO action cable
        const retryGetTransaction = async (retriesLeft) => {
            try {
                const transactionResult = await dispatch(fetchTransaction())
                if (transactionResult.status === 'canceled' || transactionResult.status === 'success') return

                if (retriesLeft <= 0) {
                    wizard.history.replace('/paymentFailed')
                    return
                }

                if (pspRequest.id) {
                    const result = await dispatch(fetchPspRequest())

                    if (result.status === 'canceled') return

                    if (retriesLeft <= 10) {
                        setShowRestartButton(true)
                    }

                    timeout = setTimeout(() => {
                        if (result.status === 'pending') {
                            retryGetTransaction(retriesLeft -= 1).then()
                        } else {
                            retryGetTransaction(retriesLeft).then()
                        }
                    }, 1000)
                } else if (voucherRequest.id) {
                    const result = await dispatch(fetchVoucherRequest())

                    timeout = setTimeout(() => {
                        if (result.status === 'pending') {
                            retryGetTransaction(retriesLeft -= 1).then()
                        } else {
                            retryGetTransaction(retriesLeft).then()
                        }
                    }, 1000)
                }
            } catch (e) {
                errorHandler(e)
            }
        }

        retryGetTransaction(40).then()

        return function cleanup () {
            clearTimeout(timeout)
        }
    }, [pspRequest.id, voucherRequest.id])

    useEffect(() => {
        if (transaction.status === 'success' && returnUrl) {
            setTimeout(() => {
                window.location.href = returnUrl
            }, 3000)
        }
    }, [transaction, returnUrl])

    const getMoneyWithTips = () => {
        return getValueCentsInCurrencyFormat(transaction.totalAmountCents, false)
    }

    const handleSubmit = async (values) => {
        try {
            await dispatch(setClientEmail(values.email))
            await dispatch(setClientLocale(i18n.language?.split('-')[0]))
            await dispatch(createOrUpdateClient())
            dispatch(persistTransaction())
            wizard.history.push('/wizard/step-6/sent')
        } catch (e) {
            errorHandler(e, dispatch)
        }
    }

    const onValidationChanged = ({ valid }) => {
        setEmailValid(valid)
    }

    if (transaction.status === 'failed') {
        return <Redirect to="/paymentFailed"/>
    }

    if (transaction.status === 'canceled' || pspRequest.status === 'canceled') {
        wizard.history.replace('/wizard/step-1')
    }

    const getPaymentDescription = () => {
        if (!paymentData.paymentMethod) {
            return t('finished.voucherPay', { voucherValue: getValueCentsInCurrencyFormat(transaction.totalAmountCents) })
        } else {
            const name = paymentData.paymentMethod.name
            const paymentMethodLabel = (name === 'credit_card') ? t('paymentTypes.creditCardLabel') : paymentData.paymentMethod.label
            if (transaction.voucherAmountCents > 0) {
                return t('finished.hybridPay', {
                    paymentLabel: paymentMethodLabel,
                    paymentValue: getValueCentsInCurrencyFormat(transaction.totalAmountCents - transaction.voucherAmountCents),
                    voucherValue: getValueCentsInCurrencyFormat(transaction.voucherAmountCents),
                })
            } else {
                return t('finished.normalPay', {
                    paymentLabel: paymentMethodLabel,
                    paymentValue: getValueCentsInCurrencyFormat(transaction.totalAmountCents - transaction.voucherAmountCents),
                })
            }
        }
    }

    const getPreviousVoucherValue = () => {
        return t('voucherBox.oldValue', { amount: getValueCentsInCurrencyFormat(voucherBalance.amountCents) })
    }

    const getCurrentVoucherValue = () => {
        return t('voucherBox.newValue', { amount: getValueCentsInCurrencyFormat(voucherBalance.amountCents + transaction.totalAmountCents) })
    }

    const getRestVoucherValue = () => {
        return t('voucherBox.restValue', { amount: getValueCentsInCurrencyFormat(Math.max(0, voucherBalance.amountCents - transaction.voucherAmountCents)) })
    }

    if (transaction.status === 'success') {
        dispatch(setTransactionFinished(true))
        const isTopUpRequest = (voucherRequest && voucherRequest.requestType === 'top_up')
        return <>
            <ConfirmationContainer stretched fluid>
                <Columns>
                    <Column>
                        <AmountText>{getMoneyWithTips()}</AmountText>
                        <StyledAmountDescriptionText>{getPaymentDescription()}</StyledAmountDescriptionText>
                        <StyledCurrencyText>EURO</StyledCurrencyText>
                    </Column>

                </Columns>
                <Columns>
                    <Column>
                        <CheckoutAnimation>
                            <CheckoutTick/>
                            <CheckoutCircle/>
                        </CheckoutAnimation>
                    </Column>
                </Columns>
                <InfoColumns expanded={isTopUpRequest}>
                    <Column>
                        <StyledIconLabel icon={locationIcon}>
                            {restaurant.name}
                        </StyledIconLabel>
                        {(isTopUpRequest) ?
                            <>
                                <StyledIconLabel icon={giftCardIcon}>
                                    {getPreviousVoucherValue()}
                                </StyledIconLabel>
                                <StyledIconLabel style={{ marginTop: '-12px' }}>
                                    {getCurrentVoucherValue()}
                                </StyledIconLabel>
                            </> :
                            <>
                                <StyledIconLabel icon={calendarIcon}>
                                    {`${formatDate(new Date(transaction.updatedAt), t('transactionDate.dateFormat'))} ${i18n.exists('clock') ? t('clock') : ''}`}
                                </StyledIconLabel>
                                {(transaction.voucherAmountCents > 0) && <StyledIconLabel icon={giftCardIcon}>
                                    {getRestVoucherValue()}
                                </StyledIconLabel>
                                }
                            </>
                        }

                    </Column>
                </InfoColumns>

            </ConfirmationContainer>
            <Footer>
                {!returnUrl &&
                <>
                    <EmailForm formId="emailForm"
                               onSubmit={handleSubmit}
                               initialValues={{ email: client.email }}
                               onValidationChanged={onValidationChanged}
                    />
                    <RemoteSubmitButton formName="emailForm" disabled={!emailValid}>
                        {loyaltyCardId ? t('finished.buttonTopup') : t('finished.button')}
                    </RemoteSubmitButton>
                </>
                }
            </Footer>
        </>
    }

    const getLoadingScreenText = () => {

        if (paymentData && paymentData.paymentMethod) {
            const paymentName = paymentData.paymentMethod.name
            const label = paymentData.paymentMethod.label

            if (paymentName === 'pay_pal' || paymentName === 'sofort' || paymentName === 'alipay') {
                return t('loadingScreen.waitingForRedirect', { paymentLabel: label })
            }
        }

        return t('loadingScreen.waitingForPayment')
    }

    const loadingScreenText = getLoadingScreenText()
    return <Processing text={loadingScreenText} showRestart={showRestartButton}/>
}

export default StepFinished
