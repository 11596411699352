import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Container from '../components/Container'
import { paymentMethodConfig } from '../paymentMethodConfig'
import PaymentTypeList from '../components/PaymentTypeList'
import { fetchTransaction, persistTransaction, setPaymentMethod } from '../actions/transactionActions'
import Footer from '../components/Footer'
import { isToGoSelector, isVoucherEnabled } from '../reducers/restaurant'
import { useTracker } from '../hooks'
import ScrollIndicator from '../components/ScrollIndicator'
import StampayVoucherBox from '../components/StampayVoucherBox'
import StampayNoVoucherBox from '../components/StampayNoVoucherBox'
import { pay } from '../services/payment'
import { includeGooglePayLibrary, onGooglePayLoaded } from '../services/googlePayApi'
import { createVoucherRequest, destroyVoucherRequest, payWithVoucher } from '../actions/voucherRequestActions'
import { setHideRestaurantInHeader } from '../actions/appStateActions'

const PaymentTypeContainer = styled(Container)`
  background-color: ${props => props.theme.headerBottomColor};
  flex-wrap: wrap; 
  flex-direction: row;
  width: 100%;
  height:auto;
  overflow: auto;
`

const Overlay = styled.div`
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height:765px;
  background-color: rgba(0,0,0,0.65);
  z-index: 20;
`

const isOverflown = ({ clientHeight, scrollHeight }) => {
    return scrollHeight > clientHeight
}

const isBottom = (element) => {
    return element.scrollHeight - element.scrollTop === element.clientHeight
}

const StepPaymentType = (props) => {
    const dispatch = useDispatch()
    const { wizard } = props

    const restaurant = useSelector(state => state.restaurant)
    const { availablePaymentMethods } = restaurant
    const supportedPaymentMethods = useSelector(state => state.appState.supportedPaymentMethods)
    const isToGo = useSelector(state => isToGoSelector(state))
    const useVoucherBox = useSelector(state => isVoucherEnabled(state))

    const transaction = useSelector(state => state.transaction)
    const voucherRequest = useSelector(state => state.voucherRequest)
    dispatch(setHideRestaurantInHeader(false))

    useTracker(null)

    // ScrollIndicator
    const containerRef = useRef()
    const [showScrollIndicator, setShowScrollIndicator] = useState(false)
    useEffect(() => {
        const handleScroll = event => {
            setShowScrollIndicator(!isBottom(containerRef.current))
        }

        window.addEventListener('scroll', handleScroll, true)

        if (containerRef.current) {
            setShowScrollIndicator(isOverflown(containerRef.current))
        }

        return function cleanup () {
            window.removeEventListener('scroll', handleScroll, true)
        }
    }, [containerRef])

    // ---

    useEffect(() => {
        (async function () {
            try {
                await includeGooglePayLibrary()
                await onGooglePayLoaded()
            } catch (e) {
                console.error(e)
            }
        })()
    }, [])

    const choosePaymentMethod = (event) => {
        const value = event.currentTarget.dataset.value
        if (value === 'voucher') {
            return finishWithVoucherCard()
        }

        const paymentMethod = getPaymentMethod(value)

        // Ugly hack for sync payment calls
        if (paymentMethod.name === 'apple_pay' || paymentMethod.name === 'google_pay') {
            pushPaymentMethod(paymentMethod).then()
            dispatch(pay(paymentMethod))
            wizard.push(paymentMethod.link)
        } else {
            pushPaymentMethod(paymentMethod).then(() => {
                dispatch(pay(paymentMethod))
                wizard.push(paymentMethod.prePaymentLink)
            })
        }
    }

    const finishWithVoucherCard = async () => {
        dispatch(payWithVoucher())
        wizard.push('step-6')
    }

    const pushPaymentMethod = async (paymentMethod) => {
        await dispatch(setPaymentMethod(paymentMethod))
        await dispatch(persistTransaction())
    }

    const getPaymentMethod = (choosenPaymentMethod) => {
        let paymentMethod = paymentMethodConfig[choosenPaymentMethod]
        let paymentMethodData = availablePaymentMethods.find(pm => pm.name === choosenPaymentMethod)
        return { ...paymentMethod, ...paymentMethodData }
    }

    const newVoucherClick = () => {
        if (transaction.voucherAmountCents > 0) {
            return // voucher is already applied
        }
        if (voucherRequest.requestType === 'charge') {
            wizard.push('step-read-voucher-card')
        }
    }

    const voucherRequestClick = async () => {
        await dispatch(createVoucherRequest())
        await dispatch(fetchTransaction())
    }

    const voucherCancelRequestClick = async () => {
        await dispatch(destroyVoucherRequest())
        await dispatch(fetchTransaction())
    }

    const showVoucherEntry = transaction.remainingAmountCents <= 0
    return (
        <>
            {useVoucherBox ?
            <StampayVoucherBox onAddVoucherClick={newVoucherClick} onUseVoucherClick={voucherRequestClick} onCancelVoucherUseClick={voucherCancelRequestClick}/>
            : <StampayNoVoucherBox />}
            <PaymentTypeContainer stretched fluid innerRef={containerRef}>
                {(showVoucherEntry) && <Overlay/>}
                <PaymentTypeList
                    showRemainingAmount={true}
                    onChange={choosePaymentMethod}
                    supportedPaymentMethods={supportedPaymentMethods}
                    availablePaymentMethods={availablePaymentMethods}
                />
            </PaymentTypeContainer>
            {isToGo && <Footer justifyContent="flex-end" style={{ padding: '0', minHeight: 0 }} isFixedOnLg={true}>
            </Footer>}
            {showScrollIndicator && !showVoucherEntry && <ScrollIndicator bottom={'0'}/>}
        </>
    )
}

export default StepPaymentType
