import API, { URLs } from '../api'
import { errorHandler } from '../errorHandler'
import { getGooglePaymentDataRequest, getGooglePaymentsClient, getGoogleTransactionInfo } from './googlePayApi'
import { createApplePaySession } from './applePayApi'
import { clearPspRequest, createPspRequest, destroyPspRequest, updatePspRequest } from '../actions/pspRequestActions'
import { persistTransaction } from '../actions/transactionActions'

export const createCreditCardInfo = (response, wirecardRequestId) => {
    return API.post(URLs.creditCardInfo, {
        credit_card_info: {
            wirecard_request_id: wirecardRequestId,
            psp_form_response: response,
            authorization_code: response.authorization_code,
        },
    })
}

//*** Thunks ***

const payWithRedirectURLs = () => {
    return async (dispatch, getState) => {
        try {
            const result = await dispatch(createPspRequest())
            // Redirect to Wirecard Payment URL
            window.location.href = result.paymentMethodRedirectUrl
        } catch (e) {
            return Promise.reject(e)
        }
    }
}

const payWithCreditCard = () => {
    return (dispatch, getState) => {
        return dispatch(createPspRequest())
    }
}

const payWithGooglePay = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const transaction = state.transaction
        const paymentMethod = state.paymentData.paymentMethod

        const transactionInfo = getGoogleTransactionInfo((transaction.remainingAmountCents / 100).toFixed(2), 'EUR')
        const paymentsClient = getGooglePaymentsClient()
        const paymentDataRequest = getGooglePaymentDataRequest(transactionInfo, paymentMethod.merchantAccountId)

        try {
            const googlePaymentData = await paymentsClient.loadPaymentData(paymentDataRequest)

            const result = await dispatch(createPspRequest({
                googlePaymentData: googlePaymentData,
                cryptogramValue: googlePaymentData.paymentMethodData.tokenizationData.token,
                cardType: googlePaymentData.paymentMethodData.info.cardNetwork,
            }))

            return Promise.resolve(result.data)
        } catch (e) {
            errorHandler(e)
        }
    }
}

const payWithApplePay = () => {
    return (dispatch, getState) => {
        const state = getState()
        const transaction = state.transaction

        // When payment method selected, update total amount
        window.applePaySession.onpaymentmethodselected = function (event) {
            window.applePaySession.completePaymentMethodSelection({
                newTotal: { label: 'stampayGO', amount: (transaction.remainingAmountCents / 100).toFixed(2) },
            })
        }

        window.applePaySession.onvalidatemerchant = function (event) {
            dispatch(createPspRequest({ validationUrl: event.validationURL }))
                .then(response => {
                    window.applePaySession.completeMerchantValidation(response.response)
                })
                .catch(e => {
                    console.error(e)
                    errorHandler(e)
                })
        }

        window.applePaySession.onpaymentauthorized = function (event) {
            dispatch(updatePspRequest({ token: JSON.stringify(event.payment.token) }))
                .then(() => {
                    window.applePaySession.completePayment(window.ApplePaySession.STATUS_SUCCESS)
                })
                .catch(() => {
                    window.applePaySession.completePayment(window.ApplePaySession.STATUS_FAILURE)
                })
        }

        window.applePaySession.oncancel = function (event) {
            dispatch(destroyPspRequest()).then(() => {
                dispatch(persistTransaction())
            })
        }

        window.applePaySession.begin()
    }
}

const availablePaymentFunctions = {
    payWithCreditCard: payWithCreditCard,
    payWithRedirectURLs: payWithRedirectURLs,
    payWithGooglePay: payWithGooglePay,
    payWithApplePay: payWithApplePay,
}

export const pay = (paymentMethod) => {
    return async (dispatch, getState) => {
        dispatch(clearPspRequest())

        if (paymentMethod.name === 'apple_pay') {
            createApplePaySession()
        }

        try {
            return dispatch(availablePaymentFunctions[paymentMethod.paymentAction]())
        } catch (e) {
            errorHandler(e)
        }
    }
}
