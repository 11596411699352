import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers'
import thunkMiddleware from 'redux-thunk'
import logger from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

export const history = createBrowserHistory()

const middlewares = [routerMiddleware(history), thunkMiddleware]

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['restaurant', 'client', 'appState', 'paymentData', 'wizardState', 'transaction', 'pspRequest', 'voucherCard', 'voucherRequest', 'voucherBalance'],
    stateReconciler: autoMergeLevel2,
}

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') {
    middlewares.push(logger)
}
const persistedReducer = persistReducer(persistConfig, createRootReducer(history))

function configureStore (preloadedState) {
    const store = createStore(persistedReducer, preloadedState, composeWithDevTools(applyMiddleware(...middlewares)))

    // Hot reloading
    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./reducers', () => {
            store.replaceReducer(createRootReducer(history))
        })
    }

    return store
}

export const store = configureStore({})
export const persistor = persistStore(store)
