import axios from 'axios'

export const URLs = {
    restaurants: '/v1/restaurants',
    transactions: '/v1/transactions',
    clients: '/v1/clients',
    paymentGatewayTransactions: '/v1/payment_gateway_transactions',
    wirecardRequests: '/v1/wirecard_requests',
    googlePay: '/v1/google_pays',
    applePay: '/v1/apple_pays',
    payPal: '/v1/pay_pals',
    sofort: '/v1/soforts',
    alipay: '/v1/alipays',
    wechat: '/v1/wechats',
    paydirekt: '/v1/paydirekts',
    creditCard: '/v1/credit_cards',
    creditCardInfo: '/v1/credit_card_infos',
    voucherCards: '/v1/voucher_cards',
    voucherRequest: 'v1/voucher_requests',
}

export default axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    maxRedirects: 0,
})
