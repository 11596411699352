import { CLEAR_PSP_REQUEST_STATE, REMOVE_PSP_REQUEST, SET_PSP_REQUEST } from './types'
import { createPSPRequest, deletePSPRequest, getPSPRequest, updatePSPRequest } from '../services/psqRequests'
import { capitalize, snakeToCamel } from '../utils'

export const setPspRequest = (payload) => {
    return {
        type: SET_PSP_REQUEST,
        payload: payload,
    }
}

export const removePspRequest = () => {
    return {
        type: REMOVE_PSP_REQUEST,
    }
}

export const clearPspRequest = () => {
    return {
        type: CLEAR_PSP_REQUEST_STATE,
    }
}

// Thunk actions (async)

export const fetchPspRequest = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const pspRequest = state.pspRequest

        try {
            const result = await getPSPRequest(pspRequest.id)

            dispatch(setPspRequest(result.data))

            return Promise.resolve(result.data)
        } catch (e) {
            if (e.message === 'Network Error') {
                //for now we need this as a quick fix for a safari and firefox bug
                //https://bugzilla.mozilla.org/show_bug.cgi?id=1280189
                return Promise.resolve(pspRequest)
            } else {
                return Promise.reject(e)
            }
        }
    }
}

export const createPspRequest = (data = {}) => {
    return async (dispatch, getState) => {
        const state = getState()
        const transaction = state.transaction
        const paymentMethod = state.paymentData.paymentMethod

        try {
            const result = await createPSPRequest(transaction.id, { ...data, type: capitalize(snakeToCamel(paymentMethod.name)) })

            dispatch(setPspRequest(result.data))

            return Promise.resolve(result.data)
        } catch (e) {
            return Promise.reject(e)
        }
    }
}

export const updatePspRequest = (data = {}) => {
    return async (dispatch, getState) => {
        const state = getState()
        const pspRequest = state.pspRequest

        try {
            const result = await updatePSPRequest(pspRequest.id, data)

            dispatch(setPspRequest(result.data))

            return Promise.resolve(result.data)
        } catch (e) {
            return Promise.reject(e)
        }
    }
}

/**
 * Cancels the voucher request on the server
 * And sets id to null in state
 * @return Promise
 */
export const destroyPspRequest = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const pspRequest = state.pspRequest

        try {
            await deletePSPRequest(pspRequest.id)
            dispatch(fetchPspRequest())
            dispatch(removePspRequest())

            return Promise.resolve()
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
