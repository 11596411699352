import { AliPay, ApplePay, GooglePay, Paydirekt, PayPal, Sofort, VisaMastercardAmex, Wechat } from './images/payment'

export const paymentMethodConfig = {
    apple_pay: {
        icon: ApplePay,
        iconStyle: { height: '50px', maxWidth: '100%' },
        label: 'Apple Pay',
        prePaymentLink: 'step-6',
        link: 'step-6',
        paymentAction: 'payWithApplePay',
    },
    google_pay: {
        icon: GooglePay,
        iconStyle: { height: '50px', maxWidth: '100%' },
        label: 'Google Pay',
        prePaymentLink: 'step-6',
        link: 'step-6',
        paymentAction: 'payWithGooglePay',
    },
    credit_card: {
        icon: VisaMastercardAmex,
        iconStyle: { height: '40px', maxWidth: '60%' },
        label: 'Visa/Mastercard',
        prePaymentLink: 'step-credentials',
        link: 'step-credentials',
        paymentAction: 'payWithCreditCard',
    },
    pay_pal: {
        icon: PayPal,
        iconStyle: { height: 'auto', maxWidth: '100%' },
        label: 'PayPal',
        prePaymentLink: 'step-start-payment',
        link: 'step-6',
        paymentAction: 'payWithRedirectURLs',
    },
    sofort: {
        icon: Sofort,
        iconStyle: { height: '50px', maxWidth: '100%' },
        label: 'Klarna/Sofortüberweisung',
        prePaymentLink: 'step-start-payment',
        link: 'step-6',
        paymentAction: 'payWithRedirectURLs',
    },
    alipay: {
        icon: AliPay,
        iconStyle: { height: '40px', maxWidth: '100%' },
        label: 'AliPay',
        prePaymentLink: 'step-start-payment',
        link: 'step-6',
        paymentAction: 'payWithRedirectURLs',
    },
    wechat: {
        icon: Wechat,
        iconStyle: { height: '40px', maxWidth: '100%' },
        label: 'Wechat Pay',
        prePaymentLink: 'step-start-payment',
        link: 'step-6',
        paymentAction: 'payWithRedirectURLs',
    },
    paydirekt: {
        icon: Paydirekt,
        iconStyle: { height: '40px', maxWidth: '100%' },
        label: 'Paydirekt',
        prePaymentLink: 'step-start-payment',
        link: 'step-6',
        paymentAction: 'payWithRedirectURLs',
    },
}
