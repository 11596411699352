import React, { useEffect } from 'react'
import { clearState, setAmountEditable } from '../actions/appStateActions'
import { allowPaymentMethod, setIsDonation, setPaymentGatewayData } from '../actions/paymentDataActions'
import { createOrUpdateTransaction, setAmount, setExternalTrxID, setLineItems, setWaiter } from '../actions/transactionActions'
import { setRestaurant } from '../actions/restaurantActions'
import { push } from 'connected-react-router'
import Processing from './Processing'
import queryString from 'query-string'
import { useDispatch } from 'react-redux'
import { useTracker } from '../hooks'
import { useLocation, useParams } from 'react-router-dom'
import { getRestaurant } from '../services/restaurants'
import { createVoucherCard, setVoucherQrCode } from '../actions/voucherCardActions'
import { createVoucherRequest, setRequestType } from '../actions/voucherRequestActions'

const parseURLParameters = (searchString) => {
    const parsedQueryString = queryString.parse(searchString)

    const waiter = parsedQueryString.waiter
    const externalTrxId = parsedQueryString.external_trx_id
    const amount = parsedQueryString.amount
    const returnUrl = parsedQueryString.return_url
    const cancelReturnUrl = parsedQueryString.cancel_return_url
    const invoice = parsedQueryString.invoice
    const isDonation = parsedQueryString.is_donation

    let custom = parsedQueryString.custom
    if (custom) {
        custom = JSON.parse(parsedQueryString.custom)
    }
    let billing = parsedQueryString.billing
    if (billing) {
        billing = JSON.parse(parsedQueryString.billing)
    }
    let lineItems = parsedQueryString.line_items
    if (lineItems) {
        lineItems = JSON.parse(parsedQueryString.line_items)
    }
    const notifyUrl = parsedQueryString.notify_url
    const loyaltyCardId = parsedQueryString.card_id

    const paymentGatewayData = { returnUrl, cancelReturnUrl, invoice, custom, notifyUrl, billing }

    return { amount, waiter, externalTrxId, lineItems, loyaltyCardId, paymentGatewayData, isDonation }
}

const Splash = () => {
    const dispatch = useDispatch()
    useTracker(null)
    const location = useLocation()
    const { id } = useParams()

    useEffect(() => {
        console.log(process.env.REACT_APP_VERSION)

        const { amount, waiter, externalTrxId, lineItems, loyaltyCardId, paymentGatewayData, isDonation } = parseURLParameters(location.search)

        dispatch(clearState())
        dispatch(setWaiter(waiter))
        dispatch(setExternalTrxID(externalTrxId))
        dispatch(setPaymentGatewayData(paymentGatewayData))
        dispatch(setLineItems(lineItems))

        if (isDonation != null && isDonation === '1') {
            dispatch(setIsDonation(true))
        }

        if (!isNaN(parseInt(amount))) {
            dispatch(setAmount(Number(amount)))
            dispatch(setAmountEditable(false))
        }

        // Check for available payment methods
        if (window.location.protocol === 'https:') {
            if (window.ApplePaySession) {
                if (window.ApplePaySession.supportsVersion(4) && window.ApplePaySession.canMakePayments()) {
                    dispatch(allowPaymentMethod('apple_pay'))
                }
            }
        }

        (async function () {
            try {
                const { data } = await getRestaurant(id)

                dispatch(setRestaurant(data))
                await dispatch(createOrUpdateTransaction())

                if (loyaltyCardId) {
                    dispatch(setVoucherQrCode(loyaltyCardId))
                    dispatch(setRequestType('top_up'))
                    await dispatch(createVoucherCard())
                    dispatch(createVoucherRequest())
                }

                dispatch(push('/wizard/step-1'))

            } catch (e) {
                console.error(e)
                dispatch(push('/'))
            }
        })()
    }, [])

    return (
        <Processing/>
    )
}

export default Splash
