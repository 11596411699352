import { CLEAR_PSP_REQUEST_STATE, CLEAR_STATE, REMOVE_PSP_REQUEST, SET_PSP_REQUEST } from '../actions/types'

const initState = {
    id: null,
    status: null,
    authorizationStatus: 0,
    merchantAccountId: null,
}

export function pspRequest (
    state = initState,
    action,
) {
    switch (action.type) {
        case SET_PSP_REQUEST: {
            return { ...state, ...action.payload }
        }
        case CLEAR_PSP_REQUEST_STATE: {
            return { ...initState }
        }
        case CLEAR_STATE: {
            return { ...initState }
        }
        case REMOVE_PSP_REQUEST:
            return { ...state, id: null }
        default:
            return state
    }
}
