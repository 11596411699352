import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from './views/Home'
import Wizard from './views/Wizard'
import NotFoundPage from './views/404'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { history, persistor, store } from './configureStore'
import ReactGA from 'react-ga'
import { ConnectedRouter } from 'connected-react-router'
import Maintenance from './views/Maintenance'
import PaymentFailed from './views/PaymentFailed'
import Splash from './views/Splash'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { normalize } from 'styled-normalize'
import applePayButton from './ApplePayButton'
import './i18n'

const theme = {
    danger: '#faa785', // Color for error messages
    fontSize: '16px', // Global font size
    borderRadius: '4px', // Global border radius for Buttons, input, main content, etc.
    schemeMain: '#fff', // Background color
    schemeMainDesktop: '#ffffff', // Background color on desktop devices
    mainColor: '#000', // Main Text color
    mainColorDesktop: '#000000', // Main Text color on desktop devices
    secondaryInputColor: '#b8b8b8',
    cardInputBorderColor: '#d6d6d6',
    mainButtonColor: '#fff',
    defaultInputBorderColor: '#000',
    inputColor: '#000', // Input text color
    inputColorDesktop: '#000', // Input text color on desktop devices
    contentWidthDesktop: '460px', // Content width on desktop devices
    contentBorder: '#eaeced', // Content border on desktop devices
    tooltip: '#f2f2f2', // Tooltip background color
    toolTipDesktop: '#f2f2f2', // Tooltip background color on desktop devices
    desktop: '1024px', // Desktop breakpoint
    blockSpacing: '1.5rem', // Standard block spacing between columns
    containerPadding: '20px', // Padding for non-fluid container
    disabledButtonBackgroundColor: '#f1f1f1',
    disabledButtonColor: '#888',
    buttonColor: '#03787C', // Button background color03787c
    buttonColorInverted: '#faa685', // Button background color for inverted buttons
    buttonColorDesktop: '#03787C', // Button background color on desktop devices
    buttonColorInvertedDesktop: '#faa685', // Button background color for inverted buttons on desktop devices
    highlight: '#faa685', // Text color for highlighted text
    highlightDesktop: '#03787B', // Text color for highlighted text on desktop devices
    wizardNavigation: '#faa685', // Color for navigation bar in the wizard
    blinkingBackground: '#faa685', // Background color for blinking background screen
    finishedBorder: '#faa685', // Background and border color for the finished screen
    finishedBorderDesktop: '#03787B', // Background and border color for the finished screen on desktop devices
    navigationBorder: '#03787B', // Navigation border color
    tipsChooser: {
        background: 'white', // Background color for tips chooser item
        selectedColor: 'black', // Selected text color for tips chooser item
        selectedBackground: 'white', // Selected background color for tips chooser item
        backgroundDesktop: 'white', // Background color for tips chooser item on desktop devices
        selectedColorDesktop: 'black', // Selected text color for tips chooser item on desktop devices
        selectedBackgroundDesktop: 'white', // Selected background color for tips chooser item on desktop devices
        selectedBorderColor: '#03787C',
    },
    headerBottomColor: '#f2f2f2',
    voucherBoxTextColor: '#000',
    voucherSecondaryButtonColor: '#f1f1f1',
    voucherBoxBackgroundColor: '#fff',
    voucherBoxBorderRadius: '10px',
    focalWidgetTextColor: '#fff',
    focalWidgetBackgroundColor: '#000',
    actionColor: '#e7f3f3',
    boldActionColor: '#03787c',
    boldActionColorDisabled: '#c3dfe0',
}

const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${applePayButton}

  /**
   * Some normalization
   */

  html {
    box-sizing: border-box;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  iframe {
    border: 0;
  }

  figure {
    margin: 0;
    padding: 0;
  }

  /*
   * Credits to bulma.io
   */

  html {
    background-color: ${props => props.theme.schemeMain};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${props => props.theme.fontSize};
    overflow: hidden;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;

    @media (min-width: ${props => props.theme.desktop}) {
      background: ${props => props.theme.schemeMainDesktop};
      overflow: auto;
    }
  }

  body,
  button,
  input,
  select,
  textarea {
    font-family: "Montserrat", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  }

  body {
    color: ${props => props.theme.mainColor};
    font-size: 1.0em;
    font-weight: 400;
    line-height: 1.5;

    @media (min-width: ${props => props.theme.desktop}) {
      color: ${props => props.theme.mainColorDesktop};
    }
  }

  button {
    color: ${props => props.theme.mainButtonColor};
  }
  small {
    font-size: 0.875em;
  }

  span {
    font-style: inherit;
    font-weight: inherit;
  }

  strong {
    font-weight: 600;
  }

  svg {
    display: block;
  }

  // ***
  html,
  body {
    min-height: 100vh;
    min-height: fill-available;
  }
  
  h1,h2,h3,h4 {
    font-weight: 500;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
      webkit-box-shadow: 0 0 0 1000px ${props => props.theme.tooltip} inset;
      background-color: ${props => props.theme.tooltip};
    }

  #root {
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100%;
    width: 100%;

    @media (min-width: ${props => props.theme.desktop}) {
      margin: 1rem auto 0;
      width: ${props => props.theme.contentWidthDesktop};
      position: relative;
      border: 1px solid ${props => props.theme.contentBorder};
      min-height: 640px;
      border-radius: ${props => props.theme.borderRadius};
    }
  }
`

function App () {
    ReactGA.initialize('UA-30873851-14', { debug: process.env.NODE_ENV === 'development' })

    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Suspense fallback="">
                        <ConnectedRouter history={history}>
                            <Switch>
                                <Route path="/restaurants/:id" component={Splash}/>
                                <Route path="/welcome" component={Home}/>
                                <Route path="/wizard" component={Wizard}/>
                                <Route path="/paymentFailed" component={PaymentFailed}/>
                                <Route path="/maintenance" component={Maintenance}/>
                                <Route path="*" component={NotFoundPage}/>
                            </Switch>
                        </ConnectedRouter>
                    </Suspense>
                </PersistGate>
            </Provider>
            <GlobalStyle/>
        </ThemeProvider>
    )
}

export default App
