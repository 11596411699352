import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { client } from './client'
import { appState } from './appState'
import { restaurant } from './restaurant'
import { paymentData } from './paymentData'
import { wizardState } from './wizardState'
import { transaction } from './transaction'
import { voucherRequest } from './voucherRequest'
import { pspRequest } from './pspRequest'
import { voucherCard } from './voucherCard'
import { voucherBalance } from './voucherBalance'

export default (history) => combineReducers({
    router: connectRouter(history),
    client,
    appState,
    restaurant,
    paymentData,
    wizardState,
    transaction,
    voucherCard,
    voucherRequest,
    voucherBalance,
    pspRequest,
})
