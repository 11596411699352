import { CLEAR_STATE, SET_RESTAURANT } from '../actions/types'

const initState = {
    id: null,
    name: null,
    availablePaymentMethods: [],
    configurations: [],
}

export function restaurant (
    state = initState,
    action,
) {
    switch (action.type) {
        case SET_RESTAURANT: {
            const { id, name, paymentMethods, configurations } = action.payload

            return {
                ...state,
                id,
                name,
                availablePaymentMethods: paymentMethods,
                configurations: configurations,
            }
        }
        case CLEAR_STATE:
            return { ...initState }
        default:
            return state
    }
}

export function isToGoSelector (state) {
    return state.restaurant.configurations.filter(e => e.configType === 'togo')[0]?.value === 1
}

export function isVoucherEnabled (state) {
    return state.restaurant.configurations.filter(e => e.configType === 'enable_voucher')[0]?.value === 1
}

export function shouldSkipTipsSelector (state) {
    return state.restaurant.configurations.filter(e => e.configType === 'skip_tips')[0]?.value === 1
}

export function shouldSkipCameraSelector (state) {
    const config = state.restaurant.configurations.filter(e => e.configType === 'skip_camera')[0]?.value
    const enteredAmountCents = state.transaction.enteredAmountCents

    return config === 1 && enteredAmountCents > 0
}
