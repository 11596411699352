import {
    ALLOW_PAYMENT_METHOD,
    CLEAR_STATE,
    INIT_IS_BILL_SCANNED,
    RECEIPT_SCANNED,
    SET_AMOUNT_EDITABLE,
    SET_HIDE_RESTAURANT_IN_HEADER,
    SET_TRANSACTION_FINISHED,
    SET_XHR_PROGRESS,
} from '../actions/types'

const initState = {
    transactionFinished: false,
    isBillScanned: false,
    supportedPaymentMethods: ['credit_card', 'pay_pal', 'sofort', 'alipay', 'wechat', 'paydirekt', 'google_pay'],
    amountEditable: true,
    xhrInProgress: false,
    hideRestaurantInHeader: false,
}

export function appState (
    state = initState,
    action,
) {
    switch (action.type) {
        case RECEIPT_SCANNED: {
            return { ...state, isBillScanned: true }
        }
        case SET_TRANSACTION_FINISHED: {
            return { ...state, transactionFinished: action.payload }
        }
        case INIT_IS_BILL_SCANNED:
            return { ...state, isBillScanned: false }
        case ALLOW_PAYMENT_METHOD: {
            const { supportedPaymentMethods } = state
            if (!supportedPaymentMethods.includes(action.payload)) supportedPaymentMethods.push(action.payload)
            return { ...state, supportedPaymentMethods }
        }
        case SET_AMOUNT_EDITABLE:
            return { ...state, amountEditable: action.payload }
        case SET_XHR_PROGRESS:
            return { ...state, xhrInProgress: action.payload }
        case SET_HIDE_RESTAURANT_IN_HEADER:
            return { ...state, hideRestaurantInHeader: action.payload }
        case CLEAR_STATE:
            return { ...initState }
        default:
            return state
    }
}
