import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Label from './Label'
import { Column, Columns } from './Columns'
import Image from './Image'

const StyledLabel = styled(Label)`
     width: 100%;
     color: ${props => props.theme.mainColor};
     margin-bottom: 0;
     ${props => props.weight === 'bold' && 'font-weight: bold;'};
     ${props => props.weight === 'lighter' && 'font-weight: lighter;'};
     ${props => !isNaN(props.weight) && 'font-weight:' + props.weight};
`

const Icon = styled(Image)`
      max-width: 22px;
      float:left;
`

const IconLabelContainer = styled(Columns)`
  width: 100%;
`

const IconLabel = (props) => {
    const { icon, children, ...rest } = props

    return (
        <IconLabelContainer>
            <Column width="10%">
                <Icon src={icon}/>
            </Column>
            <Column>
                <StyledLabel {...rest}>{children}</StyledLabel>
            </Column>
        </IconLabelContainer>

    )
}

IconLabel.propTypes = {
    icon: PropTypes.any,
    variant: PropTypes.oneOf(['small', 'normal', 'medium', 'large']),
    align: PropTypes.oneOf(['left', 'center', 'right']),
}

IconLabel.defaultProps = {
    variant: 'normal',
    align: 'left',
}

export default IconLabel
