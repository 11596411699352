import { ALLOW_PAYMENT_METHOD, RECEIPT_SCANNED, SET_IS_DONATION, SET_PAYMENT_GATEWAY_DATA } from './types'

export const allowPaymentMethod = method => {
    return {
        type: ALLOW_PAYMENT_METHOD,
        payload: method,
    }
}

export const setReceiptScanned = (payload) => {
    return {
        type: RECEIPT_SCANNED,
        payload: payload,
    }
}

export const setPaymentGatewayData = payload => {
    return {
        type: SET_PAYMENT_GATEWAY_DATA,
        payload: payload,
    }
}

export const setIsDonation = payload => {
    return {
        type: SET_IS_DONATION,
        payload: payload,
    }
}
