import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import Container from '../components/Container'
import Footer from '../components/Footer'
import Button from '../components/Button'
import { useTranslation } from 'react-i18next'
import { renderCreditCardForm, submitCreditCardForm } from '../services/wirecardSeamlessPage'
import Processing from './Processing'
import { useTracker } from '../hooks'
import Text from '../components/Text'

const FormWrapper = styled.div`
    display: ${props => props.display};
    flex: 1 1 auto;
    margin-top: 10px;
  
    iframe {
      flex: 1 1 auto;
    }
    
    .wirecard-seamless-frame {
        height: initial;
        width: initial;   
    }
`

const StyledText = styled(Text)`
  display: none;
  
  @media (min-width: ${props => props.theme.desktop}) {
    display: block;
    font-size: 15px;
    font-weight: 700;
    margin-left: ${props => props.theme.containerPadding};
    margin-top: 1rem;
  }
`

const StepPaymentCredential = (props) => {
    const [formLoaded, setFormLoaded] = useState(false)
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation()
    useTracker(null)
    const theme = useTheme()
    const pspRequest = useSelector(state => state.pspRequest)

    useEffect(() => {
        if (!pspRequest.id) return

        (async function () {
            window.addEventListener('message', handleMessageFromIFrame, false)

            try {
                await dispatch(renderCreditCardForm(i18n))
                setFormLoaded(true)
            } catch (e) {
                console.error(e)
            }
        })()

        return function cleanup () {
            window.removeEventListener('message', handleMessageFromIFrame, false)
        }
    }, [pspRequest])

    // Handles incoming postMessage from the iframe
    const handleMessageFromIFrame = (event) => {
        // Check if message is trusted
        if (event.origin === 'https://api-test.wirecard.com' || event.origin === 'https://api.wirecard.com') {
            const message = JSON.parse(event.data)
            if (message && message.stampayGO && message.stampayGO.loaded) {
                event.source.postMessage(JSON.stringify({ stampayGO: { label: theme.mainColor, inputBorder: `1px solid ${theme.defaultInputBorderColor}` } }), '*')
            }
        }
    }

    const onSubmitForm = async () => {
        setFormLoaded(false)
        try {
            await dispatch(submitCreditCardForm())

            props.wizard.next()
        } catch (e) {
            setFormLoaded(true)
        }
    }

    if (formLoaded) {
        return <>
            <Container stretched fluid>
                <StyledText align="left">{t('paymentCredentials.hint')}</StyledText>
                <FormWrapper id="seamless-credit-card-form" display="flex"/>
            </Container>
            <Footer>
                <Button onClick={onSubmitForm}>{t('paymentCredentials.button')}</Button>
            </Footer>
        </>
    }

    return <Container stretched fluid>
        <StyledText align="left"> { (formLoaded) && t('paymentCredentials.hint')}</StyledText>
        <FormWrapper id="seamless-credit-card-form" display="none"/>
        <Processing/>
    </Container>
}

export default StepPaymentCredential
