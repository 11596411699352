import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CurrencyForm from '../components/CurrencyForm'
import Container from '../components/Container'
import Footer from '../components/Footer'
import WelcomePopup from '../components/WelcomePopup'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { shouldSkipTipsSelector } from '../reducers/restaurant'
import { persistTransaction, setAmount, setTipsAmount } from '../actions/transactionActions'
import { isPaymentGatewayModeSelector } from '../reducers/paymentData'
import TipsChooser from '../components/TipsChooser'
import { useTracker } from '../hooks'
import Text from '../components/Text'
import { Column, Columns } from '../components/Columns'
import Headline from '../components/Headline'
import Button from '../components/Button'
import { getValueCentsInCurrencyFormat } from '../utils'
import { useHistory } from 'react-router-dom'

const ImageWrapperRow = styled(Columns)`
  ${props => !props.visible && `
    visibility: hidden;
    height: 20px;
  `};
  
  @media (min-width: ${props => props.theme.desktop}) {
    display: none !important;
  }
`

const ExtraMarginColumn = styled(Column)`
  margin-top: 20px;
`

const StyledCurrencyText = styled.div`
  text-align: center;
  margin-top: 10px;
  margin-bottom: 50px;
`

const StyledAmountDescriptionText = styled.div`
  text-align: center;
  font-size: 0.8em;
  color: ${props => props.theme.secondaryInputColor};
`

const AmountInputWrapper = styled.div`
  height: 7.2em;
  overflow: hidden;
`

const AmountText = styled(Text)`
  font-size: 4em;
  margin-top: -4px;
`

const StepAmount = (props) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const appState = useSelector(state => state.appState)
    const transactionFinished = appState.transactionFinished
    const restaurant = useSelector(state => state.restaurant)
    if (transactionFinished) {
        history.replace('/restaurants/' + restaurant.id) //restart workflow
    }
    const { wizard } = props
    const { t } = useTranslation()
    const paymentData = useSelector(state => state.paymentData)
    const { isDonation } = paymentData
    const transaction = useSelector(state => state.transaction)
    const voucherRequest = useSelector(state => state.voucherRequest)

    const { amountEditable } = appState
    const skipTips = useSelector(state => shouldSkipTipsSelector(state))
    const isPaymentGatewayMode = useSelector(state => isPaymentGatewayModeSelector(paymentData))

    const [moneyWithTips, setMoneyWithTips] = useState(getValueCentsInCurrencyFormat(transaction.totalAmountCents, false))
    const amountCurrency = getValueCentsInCurrencyFormat(transaction.enteredAmountCents)
    const tipsCents = transaction.tipsAmountCents
    const tipsCurrency = getValueCentsInCurrencyFormat((tipsCents))

    const [isNewFlow, setIsNewFlow] = useState(transaction.enteredAmountCents === 0 || !amountEditable)
    const [isEditingAmount, setIsEditingAmount] = useState((!(tipsCents > 0)))

    useTracker(null)

    useEffect(() => {
        setMoneyWithTips((transaction.totalAmountCents / 100).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
    }, [transaction.totalAmountCents])

    useEffect(() => {
        if (voucherRequest.requestType === 'top_up') {
            wizard.next()
        }
    }, [voucherRequest])

    const handleChange = (amount) => {
        dispatch(setAmount(amount))
    }

    const handleOnBlur = async (amount) => {
        if (transaction.tipsPercentage === 0) {
            dispatch(setTipsAmount(0))
        } else {
            dispatch(setTipsAmount(null))
        }
        await dispatch(persistTransaction())
        setIsEditingAmount(false)
    }

    if (isPaymentGatewayMode) {
        wizard.next()
    }

    const getHeadline = () => {
        if (isDonation) {
            return t('amount.donation.title')
        }
        if ((transaction.tipsAmountCents > 0 || transaction.tipsPercentage > 0) && !isEditingAmount) {
            return t('amount.simpleTitleWithTips')
        }
        return t('amount.simpleTitle')
    }
    return <>
        <WelcomePopup hide={!isNewFlow} onButtonClick={() => {
            setIsNewFlow(false)
            setIsEditingAmount(true)
        }}/>
        <Container>
            <ImageWrapperRow/>
            <Columns>

                <ExtraMarginColumn>
                    <Headline xsmaller>{getHeadline()}</Headline>
                    <AmountInputWrapper>
                        {(amountEditable && (isEditingAmount || transaction.enteredAmountCents === 0) && !isNewFlow) ?
                            <CurrencyForm
                                onChange={handleChange}
                                onSubmit={() => {}}
                                onBlur={handleOnBlur}
                                onKeyUp={(e) => (e.keyCode === 13) && handleOnBlur()}
                                autoFocus={!isNewFlow}
                                initialValues={{ amount: transaction.enteredAmountCents || 0 }}
                                disabled={!amountEditable}
                            /> :
                            <>
                                <AmountText onClick={() => amountEditable && setIsEditingAmount(true)}>{moneyWithTips}</AmountText>
                                {transaction.totalAmountCents > transaction.enteredAmountCents &&
                                <StyledAmountDescriptionText>{t('amount.simpleTitle')} {amountCurrency} + {t('tips.title3')} {tipsCurrency}</StyledAmountDescriptionText>}
                            </>
                        }
                    </AmountInputWrapper>
                    <StyledCurrencyText>EURO</StyledCurrencyText>
                </ExtraMarginColumn>
            </Columns>

            {!skipTips &&
            <>

                <Columns marginBottom="0">
                    <Column>
                        <TipsChooser isToGo={true}/>
                    </Column>
                </Columns>
            </>
            }

        </Container>
        <Footer>
            <Button large disabled={transaction.totalAmountCents <= 0} wizard={wizard} onClick={() => {wizard.next()}}>{t('amount.selectPaymentMethod')}</Button>
        </Footer>
    </>
}

export default StepAmount
