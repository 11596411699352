import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { WithWizard } from 'react-albus'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Logo } from '../images/logo-without-claim-black.svg'
import Figure from '../components/Figure'
import IconLabel from './IconLabel'
import locationIcon from '../images/voucher/pin_icon_black.svg'

const StyledHeader = styled.header`
  align-items: normal;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.schemeMain};
  justify-content: space-between;
`

const StyledLogo = styled(Logo)`
  padding-left: 13px;
`

const StyledLink = styled(Link)`
  padding: 10px;
`

const HeaderText = styled.div`
  color: black;
  font-weight: 500;
  background-color: ${props => props.theme.headerBottomColor};
  width: 100%;
  padding: 5px 5px 5px 18px;
  display: ${props => props.visible ? 'block' : 'none'};
`

const HeaderTextSuccess = styled(HeaderText)`
  background-color: ${props => props.theme.actionColor};
  text-align: center;
  display: ${props => props.visible ? 'block' : 'none'};
`

const HeaderToGo = () => {
    const { t } = useTranslation()
    const restaurant = useSelector(state => state.restaurant)
    const transaction = useSelector(state => state.transaction)
    const isAmountEditable = useSelector(state => state.appState.amountEditable)
    const isRestaurantHiddenInHeader = useSelector(state => state.appState.hideRestaurantInHeader)

    return (
        <WithWizard render={({ next, step }) =>
            <>
                <StyledHeader border fullWidth>
                    {step.id === 'step-6' || step.id === 'step-6/sent' ?
                        <StyledLink>
                            <Figure maxWidth="6em" maxWidthDesktop="6em"><StyledLogo/></Figure>
                        </StyledLink>
                        :
                        <StyledLink to="/wizard/step-1">
                            <Figure maxWidth="6em" maxWidthDesktop="6em"><StyledLogo/></Figure>
                        </StyledLink>}
                    <HeaderText visible={(!isRestaurantHiddenInHeader || (!isAmountEditable && transaction.status !== 'success'))}>
                        <IconLabel icon={locationIcon} align='left' weight='500'>{restaurant.name}</IconLabel>
                    </HeaderText>
                    <HeaderTextSuccess visible={(transaction.status === 'success')}>
                        {t('finished.title2')}
                    </HeaderTextSuccess>
                </StyledHeader>
            </>
        }/>
    )
}

export default HeaderToGo
