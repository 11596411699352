import API, { URLs } from '../api'

export const createPSPRequest = (transactionId, data = {}) => {
    return API.post(URLs.wirecardRequests, {
        wirecardRequest: { ...data, transaction_id: transactionId },
    }, { headers: { 'Key-Inflection': 'camel' } })
}

export const updatePSPRequest = (pspRequestId, data) => {
    return API.patch(`${URLs.wirecardRequests}/${pspRequestId}`, {
        wirecardRequest: data,
    }, { headers: { 'Key-Inflection': 'camel' } })
}

export function getPSPRequest (pspRequestId) {
    return API.get(`${URLs.wirecardRequests}/${pspRequestId}`, { headers: { 'Key-Inflection': 'camel' } })
}

export const deletePSPRequest = (pspRequestId) => {
    return API.delete(`${URLs.wirecardRequests}/${pspRequestId}`)
}
