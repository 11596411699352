export const toCamelCase = (str) => {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}

export const snakeToCamel = (str) => {
    return str.replace(/([-_][a-z])/g, group =>
        group
            .toUpperCase()
            .replace('-', '')
            .replace('_', ''),
    )
}

export const base64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || ''
    sliceSize = sliceSize || 512

    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)
        const byteNumbers = new Array(slice.length)

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }
    return new Blob(byteArrays, { type: contentType })
}

// Date Format
const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December',
]
const dayOfWeekNames = [
    'Sunday', 'Monday', 'Tuesday',
    'Wednesday', 'Thursday', 'Friday', 'Saturday',
]
export const formatDate = (date, patternStr) => {
    if (!patternStr) {
        patternStr = 'M/d/yyyy'
    }
    var day = date.getDate(),
        month = date.getMonth(),
        year = date.getFullYear(),
        hour = date.getHours(),
        minute = date.getMinutes(),
        second = date.getSeconds(),
        miliseconds = date.getMilliseconds(),
        h = hour % 12,
        hh = twoDigitPad(h),
        HH = twoDigitPad(hour),
        mm = twoDigitPad(minute),
        ss = twoDigitPad(second),
        aaa = hour < 12 ? 'AM' : 'PM',
        EEEE = dayOfWeekNames[date.getDay()],
        EEE = EEEE.substr(0, 3),
        dd = twoDigitPad(day),
        M = month + 1,
        MM = twoDigitPad(M),
        MMMM = monthNames[month],
        MMM = MMMM.substr(0, 3),
        yyyy = year + '',
        yy = yyyy.substr(2, 2)

    // checks to see if month name will be used
    if (patternStr.indexOf('MMM') > -1) {
        patternStr = patternStr
            .replace('MMMM', MMMM)
            .replace('MMM', MMM)
    } else {
        patternStr = patternStr
            .replace('MM', MM)
            .replace('M', M)
    }
    return patternStr
        .replace('hh', hh).replace('h', h)
        .replace('HH', HH).replace('H', hour)
        .replace('mm', mm).replace('m', minute)
        .replace('ss', ss).replace('s', second)
        .replace('S', miliseconds)
        .replace('dd', dd).replace('d', day)

        .replace('EEEE', EEEE).replace('EEE', EEE)
        .replace('yyyy', yyyy)
        .replace('yy', yy)
        .replace('aaa', aaa)
}

function twoDigitPad (num) {
    return num < 10 ? '0' + num : num
}

export function camelToUnderscore (string) {
    return string.replace(/([A-Z1-9])/g, '_$1').toLowerCase()
}

export function objectKeysCamelToUnderscore (object) {
    let newObject = {}

    for (let key in object) {
        newObject[camelToUnderscore(key)] = object[key]
    }

    return newObject
}

export function capitalize (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getValueCentsInCurrencyFormat(value, concatSign= true) {
    const currencyValue = (value/100).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    return (concatSign) ? currencyValue.concat(' €') : currencyValue;
}
