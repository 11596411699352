import React from 'react'
import styled from 'styled-components'
import { paymentMethodConfig } from '../paymentMethodConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Text from './Text'
import Image from './Image'
import VoucherIcon from '../images/voucher/gift_card_icon_simplified.svg'
import { useSelector } from 'react-redux'
import { getValueCentsInCurrencyFormat } from '../utils'

const PaymentType = styled.div`
  height: 85px;
  min-height: 85px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 20px; 
`

const ExpressText = styled(Text)`
  color: ${props => props.theme.focalWidgetTextColor};
  position: absolute;
  top:0;
  right:0px;
  background-color: ${props => props.theme.focalWidgetBackgroundColor};
  font-size: 0.7em;
  padding: 0 20px;
`

const Media = styled(PaymentType)`
  position: relative;
  
  &:after {
    content: " ";
    z-index: 10;
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
  }
`

const SoonText = styled(Text)`
  color: #535353;
`

const AmountDiv = styled.div`
  font-family: 'Montserrat';
  color: black; //Apply theme color with theme update 
  padding: 0 10px;
  width: 100%;
  text-align: right;
`
const PaymentTypeContainer = styled.div`
  position:relative;
  display: flex;
  width: 100%;
  min-height: 85px;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.schemeMain};
  margin: 5px 5px;
  border-radius: ${props => props.theme.borderRadius};
  overflow: hidden;
  ${props => (props.aboveOverlay) && 'z-index: 30'};
`

const PaymentTypeList = (props) => {
    const { t } = useTranslation()
    const { onChange, supportedPaymentMethods, availablePaymentMethods, showRemainingAmount } = props
    const transaction = useSelector(state => state.transaction)
    const showVoucherEntry = transaction.remainingAmountCents <= 0

    const totalAmountInTransactionCurrency = getValueCentsInCurrencyFormat(transaction.totalAmountCents)
    const remainingAmountInTransactionCurrency = getValueCentsInCurrencyFormat(transaction.remainingAmountCents)

    const buildPaymentList = () => {
        const list = []

        if (showVoucherEntry) {
            const voucher_payment = {
                id: 'voucher',
                icon: VoucherIcon,
                paymentTypeContainerStyle: {},
                iconStyle: { height: '50px', width: 'auto' },
                label: 'Voucher',
                link: 'step-6',
                paymentAction: 'payWithVoucher',
            }
            list.push(
                <PaymentTypeContainer aboveOverlay key={voucher_payment.id}>
                    <PaymentType key={voucher_payment.id} active onClick={onChange} data-value={voucher_payment.id}>
                        <Image src={voucher_payment.icon} style={voucher_payment.iconStyle} alt={voucher_payment.label}/>
                        <AmountDiv>
                            {totalAmountInTransactionCurrency}
                        </AmountDiv>
                        <div>
                            <FontAwesomeIcon icon={faChevronRight} color="black" size="lg"/>
                        </div>
                    </PaymentType>
                </PaymentTypeContainer>,
            )
        }

        for (const [key, item] of Object.entries(paymentMethodConfig)) {
            const Icon = item.icon
            const IconStyle = item.iconStyle

            if (!supportedPaymentMethods.includes(key)) continue
            if (availablePaymentMethods.length === 0) continue

            const paymentMethodData = availablePaymentMethods.find(pm => pm.name === key)
            if (!paymentMethodData) continue

            if (!paymentMethodData.discarded) {
                list.push(
                    <PaymentTypeContainer key={key}>
                        <PaymentType key={key} active onClick={onChange} data-value={key}>
                            <Image src={Icon} style={IconStyle} alt={key}/>
                            {showRemainingAmount &&
                            <AmountDiv>
                                {(transaction.remainingAmountCents > 0) ? remainingAmountInTransactionCurrency : totalAmountInTransactionCurrency}
                            </AmountDiv>}
                            <div>
                                <FontAwesomeIcon icon={faChevronRight} color="black" size="lg"/>
                            </div>
                        </PaymentType>
                        {(key === 'apple_pay' || key === 'google_pay') && <ExpressText forwardedAs="span" variant="size-5">Express</ExpressText>}
                        {(key === 'sofort') && <ExpressText forwardedAs="span" variant="size-5">Sofortüberweisung</ExpressText>}
                    </PaymentTypeContainer>,
                )
            } else if (paymentMethodData.discarded) {
                list.push(
                    <Media key={key} active={false}>
                        <Image src={Icon} style={IconStyle} alt={key}/>
                        <SoonText forwardedAs="h3">
                            {t('availableSoon')}
                        </SoonText>
                    </Media>,
                )
            }
        }

        return list
    }

    return (
        buildPaymentList()
    )
}

PaymentTypeList.propTypes = {
    onChange: PropTypes.func,
    supportedPaymentMethods: PropTypes.array.isRequired,
    availablePaymentMethods: PropTypes.array.isRequired,
    showRemainingAmount: PropTypes.bool.isRequired,
}

export default PaymentTypeList
