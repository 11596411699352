import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ReactComponent as giftCardImgFile } from '../images/voucher/gift_card_icon.svg'
import { Column, Columns } from './Columns'
import { getValueCentsInCurrencyFormat } from '../utils'
import { ReactComponent as cancelOperationIcon } from '../images/cancel_operation_icon.svg'

const GiftCardIcon = styled(giftCardImgFile)`
  width: 30px;
  height: auto;
  margin: auto;
  ${props => props.square && `
    width: 100%;
    height: 100%;
`};
`
const baseInnerContainerClass = css`
  vertical-align: middle;
  height: 100px;
`
const PaymentBoxContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 110px;
    padding-bottom: 10px;
    color: ${props => props.theme.voucherBoxTextColor};
    background-color: ${props => props.theme.headerBottomColor};
`
const LeftContainer = styled.div`
    ${baseInnerContainerClass};
    justify-content: center;
    background: ${props => props.theme.voucherBoxBackgroundColor};
    width:50%;
    display: flex;
    flex-flow: column;
`
const RightContainer = styled.div`
    ${baseInnerContainerClass};
    justify-content: center;
    width:50%;
    position: relative;
`
const ContainerCTA = styled.div`
    z-index: 10;
    display: flex;
    text-align: left;
    padding-left: 23px;
    width: 100%;
    font-size: 0.8em;
    background-color: ${props => props.theme.boldActionColor};
    color: ${props => props.theme.focalWidgetTextColor};
    font-style: italic;
    height: 32%;
    justify-content: center;
    align-items: center;
    position:relative;
`
const ContainerValue = styled.div`
    text-align: ${props => (!props.center) ? 'left' : 'center'};
    font-weight: 500;
    font-size: 1.1em;
    margin-top: -5px;
    margin-bottom: auto;
    padding-left: 24px;
    width: 100%;
`
const ContainerTitle = styled.div`
    text-align: left;
    font-weight: lighter;
    font-size: 0.8em;
    margin-top: auto;
    padding-left: 24px;
    width: 100%;
`

const ParentContainer = styled.div`
    display: block;
`

const VoucherSideColumns = styled(Columns)`
  height: 100%;
`

const VoucherLeftColumn = styled(Column)`
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 20px 0px;
  background-color: ${props => (props.blend) ? props.theme.actionColor : props.theme.actionColor};
  ${props => (props.blend || props.flex) &&
    'display: flex;'};
  & svg {
    path {
      //fill: ${props => (props.blend) ? props.theme.schemeMain : props.theme.mainColor};
    }
  }
`
const VoucherRightColumn = styled(Column)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${props => (!props.disabled) ? ((props.blend) ? props.theme.actionColor : props.theme.boldActionColor) : props.theme.boldActionColorDisabled};
  color: ${props => (props.blend) ? props.theme.mainColor : props.theme.voucherBoxBackgroundColor};
  font-size: 0.7em;
  padding: 5px;
  cursor: ${props => (!props.disabled) ? 'pointer' : 'default'};
`
const CancelVoucherButton = styled(cancelOperationIcon)`
  width: 2.2em;
  position: absolute;
  right: 10px;
  cursor: pointer;
  font-size: 0.8em;
  line-height: 1.8em;
`

const StampayVoucherBox = (props) => {
    const { onAddVoucherClick, onUseVoucherClick, onCancelVoucherUseClick } = props
    const transaction = useSelector(state => state.transaction)
    const voucherBalance = useSelector(state => state.voucherBalance)
    const voucherCard = useSelector(state => state.voucherCard)
    const voucherRequest = useSelector(state => state.voucherRequest)
    const { t } = useTranslation()

    const getVoucherCurrency = function () {
        const voucher = (transaction.voucherAmountCents > 0) ? Math.max(0, voucherBalance.amountCents - transaction.voucherAmountCents) : voucherBalance.amountCents
        const voucherCurrency = getValueCentsInCurrencyFormat(voucher)

        return voucherCurrency
    }

    const voucherAmountsCurrency = getValueCentsInCurrencyFormat(transaction.voucherAmountCents)

    return (
        <ParentContainer> {/*TODO explore solution with flex columns and Bulma*/}
            <PaymentBoxContainer>
                <LeftContainer>
                    <ContainerTitle
                        style={{ paddingLeft: '23px' }}>{(voucherRequest.requestType === 'top_up') ? t('voucherBox.topUpAmount') : t('voucherBox.openAmount')}</ContainerTitle>
                    <ContainerValue>{getValueCentsInCurrencyFormat(transaction.totalAmountCents)}</ContainerValue>
                    {(transaction.voucherAmountCents > 0) && <ContainerCTA>
                        <span style={{ width: '100%' }}>-{voucherAmountsCurrency} {t('voucherBox.voucher')}</span>
                        <CancelVoucherButton onClick={onCancelVoucherUseClick}/>
                    </ContainerCTA>}
                </LeftContainer>
                <RightContainer>
                    {(voucherRequest && voucherRequest.requestType === 'top_up') ?
                        <VoucherSideColumns>
                            <VoucherLeftColumn width="100%" flex>
                                <ContainerTitle>{t('voucherBox.currentValue')}</ContainerTitle>
                                <ContainerValue variant="small">{getVoucherCurrency()}</ContainerValue>
                            </VoucherLeftColumn>
                        </VoucherSideColumns>
                        :
                        <VoucherSideColumns onClick={(voucherCard.id && voucherRequest.status === 'validated') ? onUseVoucherClick : onAddVoucherClick}>
                            {(voucherCard.id && voucherRequest.status === 'validated') ?
                                <>
                                    <VoucherLeftColumn width="50%">
                                        <GiftCardIcon/>
                                        <ContainerValue style={{ padding: '0px' }} center variant="small">{getVoucherCurrency()}</ContainerValue>
                                    </VoucherLeftColumn>
                                    <VoucherRightColumn width="50%" disabled={(transaction.voucherAmountCents > 0)}>
                                        {(voucherCard.id) ? t('voucherBox.useVoucher') : t('voucherBox.addVoucher')}
                                    </VoucherRightColumn>
                                </> :
                                <>
                                    <VoucherLeftColumn width="50%" flex>
                                        <GiftCardIcon style={{ width: '40px' }}/>
                                    </VoucherLeftColumn>
                                    <VoucherRightColumn width="50%" disabled={(transaction.voucherAmountCents > 0)}>
                                        {(voucherCard.id) ? t('voucherBox.useVoucher') : t('voucherBox.addVoucher')}
                                    </VoucherRightColumn>
                                </>}
                        </VoucherSideColumns>}
                </RightContainer>
            </PaymentBoxContainer>
        </ParentContainer>
    )
}

StampayVoucherBox.propTypes = {
    onAddVoucherClick: PropTypes.func.isRequired,
    onUseVoucherClick: PropTypes.func.isRequired,
    onCancelVoucherUseClick: PropTypes.func.isRequired,
}

export default StampayVoucherBox
